@import '~@angular/material/theming';
@include mat-core();

$mat-romeo-primary: (50: #1BC8D6,
  100: #C5F1F4,
  200: #1BC8D6,
  300: #1BC8D6,
  400: #1BC8D6,
  500: #1BC8D6,
  600: #1BC8D6,
  700: #1BC8D6,
  800: #1BC8D6,
  900: #1BC8D6,
  A100: #1BC8D6,
  A200: #1BC8D6,
  A400: #1BC8D6,
  A700: #1BC8D6,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ));

$romeo-app-primary: mat-palette($mat-romeo-primary);
$romeo-app-warning: mat-palette($mat-yellow);
$romeo-app-danger: mat-palette($mat-red);
$romeo-app-success: mat-palette($mat-green);
$romeo-app-gray: mat-palette($mat-gray);

$romeo-app-theme: mat-light-theme((color: (primary: $romeo-app-primary,
      accent: $romeo-app-primary)));


@include angular-material-theme($romeo-app-theme);

.mat-icon {
  color: mat-color($romeo-app-gray, 300);
}

.back {
  align-self: center;
  font-size: x-large;
  min-width: 0px;
}

.mat-toolbar.mat-primary {
  background: mat-color($romeo-app-gray, A100);
}

.mat-sidenav.mat-drawer-side {
  border-right: none;
}

.mat-sidenav-content {
  margin-left: 80px;
  padding-left: 33px;
  padding-top: 24px;
  padding-right: 33px;
  height: calc(100% - 25px);
}

.mat-card {
  box-shadow: none;
}

.mat-typography h1 {
  font-weight: 300;
}

.mat-grid-tile .mat-figure {
  justify-content: left !important;
}

.mat-grid-list.main-grid-list {
  padding-left: 33px;
  padding-top: 53px;
  padding-right: 33px;
}

.mat-form-field {
  width: 90%;
}

.mat-toolbar {
  height: 80px;
}

.mat-toolbar>button {
  margin: 7px;
}

.mat-button-wrapper {
  .mat-icon {
    color: mat-color($romeo-app-gray, A100);
  }
}

.main-tab-list {
  .mat-tab-body-wrapper {
    background: #f3f3f3 !important;
    padding: 33px;
    padding-bottom: 0;
  }

  .mat-tab-label {
    background: #f3f3f3;
    opacity: 1;

    &.mat-tab-label-active {
      background: #C5F1F4;
      color: #1BC8D6;
    }
  }
}



th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-top: 1px solid mat-color($romeo-app-gray, 300);
  border-bottom: 1px solid mat-color($romeo-app-gray, 300);
  border-left: 1px solid mat-color($romeo-app-gray, 300);
}

table {
  width: 100%;
  border-spacing: 0 5px !important;
}

.mat-table {
  background: transparent;
}

td.mat-cell {
  padding: 5px 0 5px 0;
  background: #ffffff;
}

td.mat-cell:first-child {
  border-radius: 4px 0px 0px 4px;
}

td.mat-cell:last-of-type {
  padding-right: 0px !important;
  border-radius: 0px 4px 4px 0px;
}

td.mat-cell:last-child {
  border-radius: 0px 4px 4px 0px;
  border-right: 1px solid mat-color($romeo-app-gray, 300);
}

td.mat-cell:not(:first-child):not(:last-child) {
  border-right: none;
  border-left: none;
}

.mat-button-toggle-checked {
  background: mat-color($romeo-app-primary, 100);
  color: mat-color($romeo-app-primary, 500) !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 0 12px;
  width: 120px;
  font-family: Roboto;
  font-size: 16px;
}

th.mat-header-cell {
  border: none;
  color: mat-color($romeo-app-gray, 500);
  text-transform: uppercase;
  border-bottom-style: unset !important;
  font-size: 13px;
}

.mat-dialog-content {
  height: 100%;
}

.modal-title {
  text-align: left;
}

.btn-modal {
  height: 48px;
  text-transform: uppercase !important;
  height: 48px;
  appearance: none;
  -webkit-appearance: none;
  font-family: Roboto;
  font-size: 16px;
  border-style: initial;
  &.is-inverse {
    background: transparent;
    font-size: 14px;
    color: #8e8e8e;
  }
}

.btn-save {
  height: 48px;
  appearance: none;
  -webkit-appearance: none;
  border-color: #1bc8d6;
  font-family: Roboto;
  font-size: 16px;
  border-style: initial;
}

.btn-rounded {
  height: 48px;
  border-radius: 100px;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;

  .mat-icon {
    color: #ffffff !important;
  }
}

.btn-circle {
  min-height: 48px;
  min-width: 48px;
  padding: 0;
  border-radius: 100%;


  &.add {
    background: #C5F1F4;
    .mat-icon {
      color: #1BC8D6 !important;
    }
  }
  &.remove {
    background: #EAEAEA;
    .mat-icon {
      color: #A7A7A7 !important;
    }
  }
}


.add-service-button,
.add-addon-button {
  height: 48px;
  border-radius: 100px;
  background: transparent !important;
}

.mat-button-disabled {
  background: #A7A7A7 !important;
}

.input-search--white .mat-form-field-outline {
  background: #ffffff;
}
