
/* Typography
   ========================================================================== */

// Font size
@for $size from 10 through 30 {
    .fs-#{$size} {
        font-size: rem($size) !important;
    }
}

// Font weight
.fw-thin {
    font-weight: $fw-thin;
}

.fw-x-light {
    font-weight: $fw-x-light;
}

.fw-light {
    font-weight: $fw-light;
}

.fw-regular {
    font-weight: $fw-regular;
}

.fw-s-bold {
    font-weight: $fw-s-bold;
}

.fw-bold {
    font-weight: $fw-bold;
}

.fw-x-bold {
    font-weight: $fw-x-bold;
}