/* Variables
   ========================================================================== */

// Palette
$m-color: #F3F7F6;
$k-color: #000000;
$b-color: #ffffff;

$grey-a: #EAEAEA;
$grey-b: #EFEFEF;
$grey-c: #F7F7F7;
$grey-d: #888888;

$danger-color: #FF004E;
$success-color: #00D66B;
$warning-color: #ffa500;
$info-color: #FFCE00;
$link-color: #A7A7A7;
$invalid-color: #dc3545;

// Font size
$base-font-size: 16;

// Font weights
$fw-thin: 100; //
$fw-x-light: 200; //
$fw-light: 300; //
$fw-regular: 400; //
$fw-medium: 500; //
$fw-s-bold: 600; //
$fw-bold: 700; //
$fw-x-bold: 800; //
$fw-black: 900; //

// Img Path
$image-path: "../content/img/" !default;

// Font Path
$font-path: "../content/fonts/" !default;