@import '../../ui/src/lib/material/_material';
@import '../../api/src/lib/api_responses';
@import '../../ui/src/lib/common-styles/base';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.modal-close {
  position: relative;
  width: 100% !important;
  justify-content: end;
  display: grid !important;
  top: -15px;
  left: 15px;
  color: mat-color($romeo-app-gray, 400)
}

.modal-title {
  text-align: center;
  font-size: 20px;
  font-weight: bold !important;
  color: mat-color($romeo-app-gray, 900)
}

.modal-buttons {
  justify-content: center;
}

.gray {
  background: mat-color($romeo-app-gray, 200) !important;
  color: mat-color($romeo-app-gray, 600) !important;
}

.modal-content {
  text-align: center;
  font-size: 15px;
  color: mat-color($romeo-app-gray, 900);
  font-family: "Open Sans";
  position: relative;
}

.danger {
  color: mat-color($romeo-app-gray, A100) !important;
  background: mat-color($romeo-app-danger, 500) !important;
}

.primary {
  color: mat-color($romeo-app-gray, A100) !important;
  background: mat-color($romeo-app-primary, 500) !important;
}

.primary-outline {
  color: mat-color($romeo-app-primary, 500) !important;
  background: mat-color($romeo-app-gray, A100) !important;
}
