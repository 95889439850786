@import '../../../ui/src/lib/material/_material';

.success-api {
  background: mat-color($romeo-app-success, 500);
}

.warning-api {
  background: mat-color($romeo-app-warning, 500);
}

.error-api {
  background: mat-color($romeo-app-danger, 500);
}
